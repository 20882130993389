import React, { useState } from 'react'
import { Tooltip } from 'antd'
import { SendOutlined, PaperClipOutlined, LoadingOutlined } from '@ant-design/icons'
import { HugeRTEWrapper } from '../../../../../components/HugeRTEWrapper'
import { ButtonGroup } from './Controls.styles'
import { StyledButton } from '../../Button'
import MessageTemplateButton from './MessageTemplateButton'
import { ChatType } from '../types'

import { useCaseViewContext } from '../../../context/CaseViewContext'

export const Controls = ({
    chatContext,
    showModal,
    handleSendMessage,
    handleMarkTicketAsRead,
}) => {
    const { caseDetails, provider } = useCaseViewContext()
    const [messageEntered, setMessageEntered] = useState('');
    const [sendMessageLoading, setSendMessageLoading] = useState(false);

    const isSendMessageDisabled = !messageEntered || sendMessageLoading;

    const onSubmit = async () => {
        if (isSendMessageDisabled) {
            return
        }
        setSendMessageLoading(true);
        const isSuccess = await handleSendMessage(messageEntered);
        isSuccess && setMessageEntered('');
        setSendMessageLoading(false);
    }

    const ButtonGroupByChatType = {
        [ChatType.PATIENT]: (
            <div style={{ display: 'flex', gap: '3px' }}>
                <MessageTemplateButton
                    caseDetails={caseDetails}
                    physicianDetails={provider}
                    setText={setMessageEntered}
                />
                <Tooltip
                    title="Attach File"
                >
                    <StyledButton
                        size="medium"
                        Icon={PaperClipOutlined}
                        onClick={showModal}
                    />
                </Tooltip>
            </div>
        ),
        [ChatType.ADMIN]: <div />,
    }

    const handleOnKeyDown = (event) => {
        if (!event.shiftKey && event.key === 'Enter') {
            onSubmit()
        }
    }

    return (
        <div style={{ height: '240px' }}>
            <HugeRTEWrapper
                value={messageEntered}
                onChange={setMessageEntered}
                onKeyDown={handleOnKeyDown}
                onFocus={handleMarkTicketAsRead}
                height={200}
            />
            <ButtonGroup>
                {ButtonGroupByChatType[chatContext] || ButtonGroupByChatType[ChatType.PATIENT]}
                <StyledButton
                    size="medium"
                    Icon={sendMessageLoading ? LoadingOutlined : SendOutlined}
                    tooltip="Send"
                    onClick={onSubmit}
                />
            </ButtonGroup>
        </div>
    )
}
