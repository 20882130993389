import { io } from "socket.io-client";
import config from "../config";

const { webSocket } = config;

const socketTwo = io(webSocket.baseUrltwo);

export default socketTwo;

export const socketTwo_init = () => {
  socketTwo.connect();
  socketTwo.on("connect", () => {
    console.log("============ connected to socket Two ============");
  });

  socketTwo.on("disconnect", () => {
    console.log("============ disconnected socket Two ============");
  });
}

export const socketTwo_close = () => {
  if (socketTwo && typeof socketTwo.close === "function") {
    socketTwo.close();
  }
}
