import React from 'react'
import { useSelector } from 'react-redux';

// COMPONENTS
import { NoTemplatesAvailable } from './components/NoTemplatesAvailable';
import { PrescribedTreatments } from './components/PrescribedTreatments'
import { UpdateStatusModal } from './modals/UpdateStatusModal'
import { RequestedMedications } from './components/RequestedMedications'
import { SectionHeader } from '../../../SectionHeader'
import { QueuedUpPrescriptions } from './components/QueuedUpPrescriptions';
import { Actions } from './components/Actions'
import { Labs } from './components/Labs'

// MODALS
import { CompleteConsultationModal } from './modals/CompleteConsultationModal';
import { LabOrderModal } from './modals/LabOrderModal'
import { TreatmentModal } from './modals/TreatmentModal'

// HOOKS
import { useTreatments } from './hooks/useTreatments';

// CONTEXT
import { DispenseUnitProvider } from './context/DispenseUniteContext'
import { useCaseViewContext } from '../../../../context/CaseViewContext';

// STYLES
import {
    Container,
    Header3
} from "../../../../Styles"

export const Treatments = () => {

    const {
        caseDetails,
        provider
    } = useCaseViewContext()

    const {
        treatmentModalState,
        setTreatmentModalMode,
        setShowLabOrderModal,
        showLabOrderModal,
        actionCaseStatus,
        setActionCaseStatus,
        dispenseUnits,
        dispenseUnitsLoading,
        getDispenseUnitById,
        selectPrescription,
        deselectPrescription,
        removePrescription,
        unTemplatizedPrescriptions,
        requestedMedications,
    } = useTreatments()

    const prescribeMedicine = useSelector(state => state.prescribeMedicine)
    const prescriptions = Object.values(prescribeMedicine.prescriptions || {})
    const isAssignedProvider = provider?.userId === caseDetails?.assignedPhysician
    return (
        <DispenseUnitProvider value={{
            dispenseUnits,
            dispenseUnitsLoading,
            getDispenseUnitById,
        }}>
            <Container>
                <SectionHeader title="Treatments">
                    <Actions
                        setActionCaseStatus={setActionCaseStatus}
                        isAssignedProvider={isAssignedProvider}
                        showTreatmentModal={() => setTreatmentModalMode('add')}
                    />
                </SectionHeader>
                <Labs setShowLabOrderModal={setShowLabOrderModal} />

                {
                    requestedMedications.length > 0 && (
                        <RequestedMedications
                            background='rgba(204, 125, 203, 0.15)'
                            color="rgba(204, 125, 203, 1)"
                            medications={requestedMedications}
                        />
                    )
                }
                {
                    !!unTemplatizedPrescriptions?.length && (
                        <NoTemplatesAvailable
                            medications={unTemplatizedPrescriptions}
                        />
                    )
                }
                <QueuedUpPrescriptions
                    prescriptions={prescriptions}
                    isAssignedProvider={isAssignedProvider}
                    selectPrescription={selectPrescription}
                    removePrescription={removePrescription}
                />
                <Header3>Already Prescribed Treatments</Header3>
                <PrescribedTreatments />
            </Container>
            <TreatmentModal
                mode={treatmentModalState.mode}
                deselectPrescription={deselectPrescription}
            />
            <CompleteConsultationModal
                unTemplatizedPrescriptions={unTemplatizedPrescriptions}
            />
            { showLabOrderModal &&
                <LabOrderModal closeLabOrderModal={() => setShowLabOrderModal(false)}/>
            }
            <UpdateStatusModal
                modalVisibility={!!actionCaseStatus}
                actionCaseStatus={actionCaseStatus}
                setCaseActionStatus={setActionCaseStatus}
            />
        </DispenseUnitProvider>
    )
}