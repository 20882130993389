import { io } from 'socket.io-client';
import config from "../config";

const { webSocket } = config;

const socket = io(webSocket.baseUrl, { secure: true, autoConnect: false, transports: ['websocket'] });

export default socket;

export const socket_init = () => {
    socket.connect();
    socket.on('connect', () => {
        console.log("============ connected to socket ============");
    })

    socket.on("disconnect", () => {
        console.log("============ disconnected socket ============");
    });
}

export const socket_close = () => {
    if (socket && typeof socket.close === "function") {
        socket.close();
    }
}
