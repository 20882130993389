import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { ImageControls } from "./ImageReviewControls";

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
`;

const ModalContent = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: white;
    border-radius: 8px;
    padding: 60px 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    transition: width 0.3s ease, height 0.3s ease;
    height: ${ ({ contentHeight }) => contentHeight }px;
    width: ${ ({ contentWidth }) => contentWidth }px;

    img {
        transform: ${({ rotation, imageScaled }) => `rotate(${rotation}deg) scale(${imageScaled ? 1.5 : 1})`};
        transition: transform 0.3s ease;
        user-select: none;
        max-width: 100%;
        max-height: 100%;
        z-index: 25;
        cursor: ${({ imageScaled }) => (imageScaled ? "zoom-out" : "zoom-in")};
    }
`;

export const ImageReviewContainer = ({ src, alt, onClose }) => {
    const [ rotation, setRotation ] = useState(0);
    const [ contentSize, setContentSize ] = useState(0);
    const [ imageScaled, setImageScaled ] = useState(false);
    const imgRef = useRef(null);
    const controlsGapPx = 60;

    const calculateRotatedSize = (width, height, angle) => {
        const radian = (angle * Math.PI) / 180;
        const sin = Math.abs(Math.sin(radian));
        const cos = Math.abs(Math.cos(radian));

        const rotatedWidth = width * cos + height * sin;
        const rotatedHeight = width * sin + height * cos;

        return { width: rotatedWidth, height: rotatedHeight };
    };

    const rotateImage = (rotation) => {
        if (imageScaled) setImageScaled(false);
        setRotation(rotation);
    }

    useEffect(() => {
        const updateImageWidth = () => {
            if (imgRef.current) {

                const { naturalWidth, naturalHeight } = imgRef.current;
                const { height: rotatedHeight } = calculateRotatedSize(
                    naturalWidth,
                    naturalHeight,
                    rotation
                );

                const maxContainerHeight = window.innerHeight * 0.9;

                let scaledWidth;
                let scaledHeight = rotatedHeight;

                if (scaledHeight > maxContainerHeight) {
                    scaledHeight = maxContainerHeight + controlsGapPx;
                    scaledWidth = maxContainerHeight - controlsGapPx;
                } else {
                    scaledWidth = maxContainerHeight + controlsGapPx;
                }
                setContentSize({ width: scaledWidth, height: scaledHeight });
            }
        };
        updateImageWidth();

        window.addEventListener("resize", updateImageWidth);
        return () => window.removeEventListener("resize", updateImageWidth);
    }, [ src, rotation ]);

    return (
        <ModalOverlay onClick={ onClose }>
            <ModalContent onClick={ e => e.stopPropagation() }
                          rotation={ rotation }
                          contentHeight={ contentSize.height }
                          contentWidth={ contentSize.width }
                          imageScaled={ imageScaled }
            >
                <ImageControls rotation={ rotation } setRotation={ rotateImage }/>
                <img ref={ imgRef }
                     src={ src }
                     alt={ alt }
                     onClick={ () => setImageScaled(!imageScaled) }
                />
            </ModalContent>
        </ModalOverlay>
    );
};
