/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Typography, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

// import APIs
import socket from "../../axios/socket";
import ChatRoomAPI from "../../axios/ChatRoomAPI";

// import components
import ImageUpload from "../../components/ChatComponent/ImageUpload";
import MessageListingComponent from "../../components/MessageListingComponent/MessageListingComponent";
import MessageHeaderComponent from "../../components/MessageHeaderComponent/MessageHeaderComponent";

const { Text } = Typography;

const MessageList = ({
  caseDetails,
  isDifferentPlatform,
  enableCaseDetailsView,
}) => {
  const { physicianDetails } = useSelector(state => state.physicianDetails)
  const [messageDataLoading, setMessageDataLoading] = useState(false);
  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  const [messageData, setMessageData] = useState({ messages: [] });
  const [pageNumber, setPageNumber] = useState(1);
  const [imageUploadVisibility, setImageUploadVisibility] = useState(false);

  useEffect(() => {
    socket.on("newPatientChatMessageRecieved", (data) => {
      const isSameCase = data.caseId === caseDetails.caseId
      if (isSameCase && data.physician === physicianDetails?.userId) {
        setMessageData(state => {
          return {...state, messages: [...state.messages, data.messageData] }
        })
      }
    });
}, [caseDetails.caseId, physicianDetails?.userId])

  const fetchMessageList = (params) => {
    setMessageDataLoading(true);
    ChatRoomAPI.getChatRoomMessages(caseDetails.caseId, params)
      .then((res) => {
        const { data } = res;
        if (data.success && data.payload) {
          const { payload } = data;
          const messageListData = payload;
          const messagesMessageList = messageData.messages;
          const messagesPayload = payload.messages.reverse();

          if (messagesMessageList) {
            messageListData.messages = messagesPayload.concat(
              messagesMessageList
            );
          }

          setMessageData(messageListData);
          return true;
        }
      })
      .catch((e) => {
        console.log( "========= Error :: Fetch Messages ===========", e);
      })
      .finally(() => {
        setMessageDataLoading(false);
      });
  };

  const markPatientChatAsRead = useCallback(async () => {
    if (!caseDetails.caseId) return
    try {
        const { data } = await ChatRoomAPI.markChatAsRead(caseDetails.caseId)
        if (!data.success) {
            throw new Error('Error marking patient chat as read')
        }
        setMessageData(state => {
          return {
            ...state,
            messages: state.messages.map(message => {
              if (message.isPatient && !message.read) {
                return { ...message, read: true };
              }
              return message;
            }),
          }
        })
    } catch (e) {
      console.log("=== Error :: Mark Chat As Read =====", e);
    }
}, [caseDetails.caseId]);

  const onImageUploadModalVisibilityChange = () => {
    setImageUploadVisibility(prevState => !prevState);
  };

  const handleMessageSending = async (data, successHandler) => {
    try {
      setSendMessageLoading(true);
      const result = await ChatRoomAPI.addMessagesToChatRoom(data)
      if (!result.data.success) {
        throw new Error()
      }
      setMessageData(state => {
        return {
          ...state,
          messages: state.messages ? [...state.messages, result.data.payload] : [result.data.payload]
        }
      })
      successHandler && successHandler()
    } catch (error) {
      console.log("========= Error :: Send Message ===========", error);
      message.error("Error. Message is not sent")
    } finally {
      setSendMessageLoading(false);
    }
  };

  const handleHeaderOptionsClick = (option) => {
    enableCaseDetailsView(caseDetails.caseId);
  };

  useEffect(() => {
    setPageNumber(1);
    setMessageData({ messages: [] });
  }, [caseDetails]);

  useEffect(() => {
    if (!caseDetails.caseId) {
      setMessageData({ messages: [] });
      return;
    }
    if (pageNumber === 1 && !messageData.messages?.length) {
      fetchMessageList({ page: 1 });
    }
  }, [messageData.messages?.length, pageNumber, caseDetails.caseId]);

  const handleLoadMore = () => {
    const params = {
      page: pageNumber + 1,
    };
    setPageNumber(pageNumber + 1);
    return fetchMessageList(params);
  };

  return (
    <>
      <Spin
        size="large"
        wrapperClassName="height-hundred"
        spinning={messageDataLoading}
        indicator={<LoadingOutlined spin />}
      >
        {Object.keys(caseDetails).length ? (
          <>
            <MessageHeaderComponent
              avatarUrl={caseDetails.photo}
              title={caseDetails.name}
              subTitle={caseDetails.categoryName}
              options={[
                {
                  title: "Case Details",
                  id: "case-details",
                },
              ]}
              onOptionsClick={handleHeaderOptionsClick}
            />
            <MessageListingComponent
              caseId={caseDetails.caseId}
              roomId={caseDetails.caseId}
              messages={messageData}
              handleLoadMore={handleLoadMore}
              pageNumber={pageNumber}
              allowImageUpload
              sendMessage={handleMessageSending}
              handleImageUpload={onImageUploadModalVisibilityChange}
              markPatientChatAsRead={markPatientChatAsRead}
              className="message-list-height-background"
              sendMessageLoading={sendMessageLoading}
            />
            {imageUploadVisibility && (
              <ImageUpload
                isPatient={false}
                isDifferentPlatform={isDifferentPlatform}
                roomId={caseDetails.caseId}
                authorId={caseDetails.physicianId}
                viewModal={imageUploadVisibility}
                closeModal={onImageUploadModalVisibilityChange}
              />
            )}
          </>
        ) : (
          <div className="message-no-chat-selection">
            <Text strong>No Chats selected for listing Messages</Text>
          </div>
        )}
      </Spin>
    </>
  );
};

export default MessageList;
