import React from 'react'

import { Prescription } from './Prescription'
import { Header3 } from '../../../../../Styles'

export const QueuedUpPrescriptions = ({
    prescriptions = [],
    isAssignedProvider,
    selectPrescription,
    removePrescription
}) => {
    return (
        <>
            <Header3>Queued Up Prescriptions</Header3>
                {
                    !prescriptions.length && (
                        <div>
                            <p>No prescriptions queued</p>
                        </div>
                    )
                }
                {
                    prescriptions.map((prescription) => {
                        return (
                            <Prescription
                                key={prescription._id}
                                background='rgba(123, 173, 126, .15)'
                                prescription={prescription}
                                isAssignedProvider={isAssignedProvider}
                                handleSelectPrescription={() => selectPrescription(prescription._id)}
                                handleRemovePrescription={() => removePrescription(prescription._id)}
                            />
                        )
                    })
                }
        </>
    )
}