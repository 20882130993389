import React from 'react'

import { 
    Skeleton,
    Popover, 
    Modal,
    message 
} from 'antd'

import { 
    PhoneFilled, 
    DesktopOutlined, 
    CloseCircleOutlined,
    ImportOutlined,
    LikeOutlined
} from '@ant-design/icons'

import { Item } from "../../../Item"
import CaseTagsComponent from "../../../../../../components/CaseTagsComponent"
import CopyStringComponent from '../../../../../../components/CopyStringComponent'
import { CaseReviewRejectionModal } from './CaseReviewRejection.Modal'
import { StyledButton } from '../../../Button'
import { StatusHistoryPopover } from './StatusHistoryPopover';

import { useClientLogo } from './hooks/useClientLogo'
import { useCaseViewContext } from '../../../../context/CaseViewContext'
import { useAudioCallContext } from '../../../../../../context/AudioCallProvider'

import { ConsultTypeEnum } from '../../../../../../types/enums'

import {
    Container,
    Header,
    SubHeader,
} from "../../../../Styles"

import {
    StyledClientLogo,
    StatusHistoryIcon
} from './Metadata.styles'

import { 
    CaseStatusEnum, 
    PhysicianTagEnum,
    CaseTagEnum
} from '../../../../../../types/enums'

import { useReferrals } from './hooks/useReferrals';
import { dateTimeFormatter } from "../../../../../../utils/dateFormatter";

const { VIDEO, AUDIO } = ConsultTypeEnum;

export const Metadata = () => {

    const { 
        caseDetails, 
        activeRequest, 
        provider,
        selfAssignCase 
    } = useCaseViewContext()

    const { 
        approveReview,
        showReviewModal,
        hideReviewModal,
        caseReviewModalVisible, 
    } = useReferrals()

    const { clientLogo } = useClientLogo({ clientId: caseDetails?.client?._id })
    const audioCall = useAudioCallContext()

    const requestStatus = activeRequest?.status
    const requestIsCompleted = requestStatus === 'COMPLETED'
    const requestTimestamp = requestIsCompleted ? activeRequest?.completedAt : activeRequest?.createdAt
    const consultationType = activeRequest?.requestType
    const isPendingLabs = caseDetails?.status === CaseStatusEnum.PENDING_LABS
    const subStatus = isPendingLabs ? ` (${requestStatus})` : ''
    const constulationStatus = requestIsCompleted ? `Completed` : `${caseDetails?.status} ${subStatus}`
    const providerName = caseDetails?.provider ? `${caseDetails?.provider?.firstName} ${caseDetails?.provider?.lastName}` : 'Unassigned'
    const isReviewerPhysician =  provider?.tags?.includes(PhysicianTagEnum.REVIEWER)
    const isLeadProvider = provider?.tags?.includes(PhysicianTagEnum.LEAD_PHYSICIAN) 
    const isCurrentlyAssignedProvider = caseDetails?.assignedPhysician === provider.userId
    const caseIsReviewed = caseDetails?.tags?.includes(CaseTagEnum.REVIEWED)
    const isEligibleProvider = provider?.teleMedicineCategories.map(category => category.toLowerCase()).includes(caseDetails?.category?.name?.toLowerCase())
    
    const canTakeOverCase = caseDetails?.status === CaseStatusEnum.REFER &&
        isLeadProvider &&
        isEligibleProvider &&
        !isCurrentlyAssignedProvider && 
        !requestIsCompleted

    const onClickPhone = () => {
        audioCall.initiate(caseDetails?._id)
    }

    const onClickVideo = () => {
        if (!provider.meetingLink) {
            return message.warning("No meeting link configured. Please contact your administrator.")
        }   

        window.open(provider.meetingLink, '_blank', 'location=yes,scrollbars=yes,status=yes')
    }

    return (
        <Container>
            <Skeleton loading={!consultationType || !caseDetails} >
                <div style={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                }}>
                    <Header>{`${consultationType} Consultation`}</Header>
                    <div style={{ display: 'flex', gap: '5px'}}>
                        <StyledButton 
                            size='medium' 
                            Icon={PhoneFilled} 
                            tooltip="Call the patient"
                            onClick={onClickPhone}
                        />    
                        { 
                            [CaseStatusEnum.PENDING_VIDEO_CONSULT].includes(caseDetails?.status) && 
                            consultationType?.toLowerCase() === 'initial' && (
                                <StyledButton 
                                    size='medium' 
                                    Icon={DesktopOutlined} 
                                    tooltip="Begin video consultation"
                                    onClick={onClickVideo}
                                /> 
                            )
                        }
                        { 
                            caseDetails?.status === CaseStatusEnum.REFER && 
                            isReviewerPhysician &&
                            !caseIsReviewed && (
                                <StyledButton 
                                    size='medium' 
                                    Icon={LikeOutlined} 
                                    tooltip="Confirm Rejection"
                                    onClick={approveReview}
                                /> 
                            )
                            
                        }
                        { 
                            caseDetails?.status === CaseStatusEnum.REFER && 
                            isReviewerPhysician &&
                            !caseIsReviewed && (
                                <StyledButton 
                                    size='medium' 
                                    color="red" 
                                    Icon={CloseCircleOutlined} 
                                    tooltip="Return to Provider"
                                    onClick={showReviewModal}
                                /> 
                            )
                        }
                        { 
                            canTakeOverCase && (
                                <StyledButton 
                                    size='medium' 
                                    Icon={ImportOutlined} 
                                    tooltip="Take over case"
                                    color="blue"
                                    onClick={() => Modal.confirm({
                                        title: 'Take Over Case',
                                        content: 'Would you like to take over this case?',
                                        onOk: selfAssignCase
                                        })}
                                /> 
                             )
                        }
                    </div>
                </div>
                <SubHeader style={{ marginBottom: 0 }}>{caseDetails?.category.name}</SubHeader>
                { clientLogo && <StyledClientLogo src={ clientLogo } alt="Brand" /> } 
                <div style={{ marginBottom: '10px' }}>{dateTimeFormatter(requestTimestamp)}</div>
                <div>
                    <Item 
                        label={
                            <span>
                                Status
                                <Popover content={<StatusHistoryPopover statusHistory={caseDetails?.statusHistory}/>} title={<div style={{ textAlign: 'center', paddingBottom: '5px', borderBottom: '1px solid rgba(0,0,0,.2)'}}>Case Status History</div>}>
                                    <StatusHistoryIcon/>
                                </Popover>
                            </span>
                        } 
                        value={<strong>{constulationStatus}</strong>} 
                    />
                </div>
                <Item label="Case ID" value={ <CopyStringComponent text={caseDetails?._id} />} />
                <Item label="Consultation Type" value={`${caseDetails?.consultationType}`} />
                <Item label="Provider" value={providerName} />
                <Item 
                    label="Tags" 
                    value={
                        <CaseTagsComponent
                            currentTags={caseDetails?.tags}
                            caseId={caseDetails?._id}
                        />
                    } 
                />
            </Skeleton>
            <CaseReviewRejectionModal 
                visible={caseReviewModalVisible}
                onClose={hideReviewModal}
            />
        </Container>
    )
}
