import axios from "axios";
import config from "../config";
import { clearUserSession } from "./../shared/auth/clear-user-session";

const { api } = config;

const Axios = axios.create({
  baseURL: api.baseUrl,
});

Axios.defaults.withCredentials = true;

Axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        clearUserSession();
      }

      return Promise.reject(error);
    }
)

export default Axios;
