import React, { useState } from "react";
import styled from "styled-components";

import { ThumbnailImage } from "../../../pages/CaseView/Styles";
import { ImageReviewContainer } from "./ImageReviewContainer";

const Container = styled.div`
    position: relative;
    display: inline-block;
`
export const ImageReview = ({ src, alt }) => {
    const [ isZoomed, setIsZoomed ] = useState(false);
    const altText = `${ alt } thumbnail`;

    const handleImageClick = () => {
        if(!isZoomed) setIsZoomed(true);
    };

    const closeModal = () => {
        setIsZoomed(false);
    };

    return (
        <Container onClick={ handleImageClick }>
            <ThumbnailImage src={ src } alt={ altText }/>
            { isZoomed &&
                <ImageReviewContainer
                    src={ src }
                    alt={ altText }
                    onClose={ closeModal }
                />
            }
        </Container>
    );
};

