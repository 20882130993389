/* eslint-disable react-hooks/exhaustive-deps */
//import packages
import React, { useState } from "react";
import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  Upload,
  Button,
  message,
  Spin,
} from "antd";
import { InboxOutlined, LoadingOutlined } from "@ant-design/icons";

//import API functions
import ChatRoomApi from "../../../../../axios/ChatRoomAPI";
import { useCaseViewContext } from "../../../context/CaseViewContext";

const { Dragger } = Upload;

export const ImageUploadModal = ({
  closeModal,
}) => {
  const { caseDetails } = useCaseViewContext()

  const [description, setDescription] = useState(null);
  const [file, setFile] = useState(null);
  const [isUploadDisabled, setUploadDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const handleImageUploadClick = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("description", description);
    formData.append("chatRoomId", caseDetails?.caseId);
    formData.append("author", caseDetails?.physicianId);
    formData.append("isPatient", false);

    ChatRoomApi.uploadChatImage(formData)
      .then((res) => {
        if (res.data?.success) {
          message.success("Successfully uploaded image");
          closeModal()
        } else {
          message.warning("Unable upload image please try again!");
        }
      })
      .catch((error) => {
        message.error(
          "Error occured while uploading image, Please try after a while!"
        );
      })
      .finally(() => {
        setLoading(false);
      })
  };

  const handleChange = (info) => {
      const fileList = info.fileList;
      if (fileList.length > 0) {
          setFile(fileList[fileList.length - 1].originFileObj || null);
      } else {
          setFile(null);
      }
  };

  const fileUploadProps = {
    onRemove: (file) => {
      setFile(null);
    },
    beforeUpload: (file) => false,
    onChange: handleChange,
    multiple: false,
    accept: "image/png, image/jpeg",
  };

  const handleDescriptionChange = (event) => {
      const description = event.target.value;
      setDescription(description);
      setUploadDisabled(!description.length);
  };

  return (
    <Modal
      title="Upload Image File"
      visible={true}
      footer={null}
      onCancel={closeModal}
      width={720}
      destroyOnClose
      maskClosable={false}
    >
      <Spin
        size="large"
        spinning={loading}
        indicator={<LoadingOutlined spin />}
      >
        <Form {...layout} layout={"vertical"} name="chat-image-upload">
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Image Description">
                <Input size={"large"} onChange={handleDescriptionChange} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Upload image">
                <Dragger
                  {...fileUploadProps}
                  onChange={handleChange}
                  fileList={file ? [file] : []} // Only one file can be uploaded
                  disabled={isUploadDisabled}
                  className="chat-image-upload-dragger"
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag the image file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single file upload. Please also add a
                    description for image to be viewed in the chat
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
            <Col className="chat-image-upload-button-wrapper" span={24}>
              <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
                <Button
                  type="primary"
                  size={"large"}
                  className="chat-image-upload-button"
                  disabled={!file}
                  onClick={handleImageUploadClick}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};