import { useState, useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { message } from 'antd'
import PhysicianApi from '../axios/physician/PhysicianApi'
import socket from '../axios/socket'

import {
    LabOrderEventEnum,
    CaseStatusEnum,
    ConsultTypeEnum,
    PhysicianTagEnum
} from '../types/enums'

export const useCaseDetails = (caseId, provider, requestId) => {
    const history = useHistory();
    const [caseDetails, setCaseDetails] = useState(null)
    const [showLabOrderModal, setShowLabOrderModal] = useState(false)
    const [caseDetailsLoading, setCaseDetailsLoading] = useState(false)
    const [possessionChangeAction, setPossessionChangeAction] = useState(null)
    const isOtherProvidersCase = history?.location?.state?.from === 'other-physicians-cases'

    const fetchCaseDetails = useCallback(() => {
        setCaseDetailsLoading(true)
        PhysicianApi.getCaseDetailsById(caseId)
            .then((res) => setCaseDetails(res.data.payload))
            .catch(e => {
                console.log(e)
                message.error("Unable to fetch caseDetails")
            })
            .finally(() => setCaseDetailsLoading(false))
    }, [caseId, requestId])

    const selfAssignCase = () => {
        PhysicianApi.selfAssignCase({ caseId: caseDetails.caseId })
            .then(() => fetchCaseDetails())
            .catch(e => message.error(e.response?.data?.message))
    }

    const startConsultation = async () => {
        try {
            const { data } = await PhysicianApi.startConsultation({
                caseId: caseDetails.caseId,
                consultationStart: true,
            })

            if (!data?.success) {
                throw new Error("Error occurred while Starting Consultation. Please try again later")
            }

            message.success("Successfully started consultation!");
            setPossessionChangeAction(null)
        } catch (e) {
            message.error(e.response?.data?.message || e.message)
        }
    };

    useEffect(() => {
        if (caseDetails && provider) {
            const caseIsCompleted = [CaseStatusEnum.CONSULT_COMPLETE, CaseStatusEnum.CONSULT_COMPLETE_NO_RX].includes(caseDetails.status)
            if (caseDetails.consultationType === ConsultTypeEnum.STORE_AND_FORWARD && !caseIsCompleted) {
                if (!caseDetails.consultationStart) {
                    if (!isOtherProvidersCase) {
                        setPossessionChangeAction('accept')
                    }
                } else if (caseDetails.provider._id !== provider.userId) {
                    const caseNeedsReview = caseDetails.status === CaseStatusEnum.REFER && provider.tags.includes(PhysicianTagEnum.REVIEWER)
                    !caseNeedsReview && setPossessionChangeAction('accept')
                }
            }
        }
    }, [caseDetails, provider])


    useEffect(() => {
        if (caseId) {
            fetchCaseDetails()
        }
    }, [caseId, requestId])

    useEffect(() => {
        socket.on("physicianCaseOverride", ({ caseId: id, physicianId }) => {
            if (id === caseId && physicianId !== provider?.userId) {
                setPossessionChangeAction('reassigned')
            }
        });

        socket.on(LabOrderEventEnum.ORDER_PLACED, async ({ caseId: id }) => {
            if (caseId === id) {
                message.success('Lab order created')
                setShowLabOrderModal(false)
                fetchCaseDetails()
            }
        })

        return () => {
            socket.removeAllListeners([
                "physicianCaseOverride",
                LabOrderEventEnum.ORDER_PLACED,
            ]);
        }
    }, [provider, caseDetails])

    return {
        caseDetails,
        setCaseDetails,
        caseDetailsLoading,
        fetchCaseDetails,
        selfAssignCase,
        startConsultation,
        possessionChangeAction,
        setPossessionChangeAction,
        showLabOrderModal,
        setShowLabOrderModal
    }
}
