import React, { useState, useEffect } from 'react'

import {
    Typography,
    Form,
    Checkbox,
    Select,
    Spin,
    message
} from 'antd'

import PhysicianAPI from '../../../../../../../../../../../axios/physician/PhysicianApi'
import { HugeRTEWrapper } from '../../../../../../../../../../../components/HugeRTEWrapper'
import { useCaseViewContext } from '../../../../../../../../../context/CaseViewContext'

const { Text } = Typography

const PatientMessage = ({ form }) => {
    const { caseDetails, provider } = useCaseViewContext()
    const [loading, setLoading] = useState(true)
    const [templateType, selectTemplateType] = useState('my')
    const [availableTemplates, setAvailableTemplates] = useState([])
    const [sendPatientMessage, setSendPatientMessage] = useState(true)

    const getTemplateMessages = () => {
        const filters = {
          categoryIds: [caseDetails?.categoryId],
          status: 'consult complete',
        };

        if (templateType === 'my') {
            filters.owner = provider.userId
        }

        PhysicianAPI.getTemplates('case', { filters })
          .then(({ data: { templates } }) => setAvailableTemplates(templates.sort(a => a.favoritedBy ? -1 : 1)))
          .catch(() => message.error('Error loading patient message templates'))
          .finally(() => setLoading(false))
    }

    const handleTemplateSelect = message => {
        form.setFieldsValue({ patientExplanation: message})
    }

    const toggleSendPatientMessage = event => {
        !event.target.checked && form.setFieldsValue({ patientExplanation: ''})
        setSendPatientMessage(event.target.checked)
    }

    // eslint-disable-next-line
    useEffect(getTemplateMessages, [templateType])

    return (
        <Spin spinning={loading}>
            <Text
                strong
                style={{ display: 'block', marginBottom: '10px'}}
            >
                Patient Message
            </Text>
            <Form.Item onChange={toggleSendPatientMessage}>
                <Checkbox checked={sendPatientMessage}>
                    Send message to patient
                </Checkbox>
            </Form.Item>
            {
                sendPatientMessage && (
                    <>
                        <Form.Item label='Select a patient message template'>
                            <Form.Item>
                                <Select value={templateType} onChange={selectTemplateType}>
                                    <Select.Option value='custom'>Freeform Message</Select.Option>
                                    <Select.Option value='shared'>Shared Templates</Select.Option>
                                    <Select.Option value='my'>My Templates</Select.Option>
                                    <Select.Option value='admin'>Admin Templates</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="templateName">
                                <Select
                                    placeholder="Select a template"
                                    disabled={templateType === 'custom'}
                                    onChange={handleTemplateSelect}
                                    options={availableTemplates.map(template => ({ label: template.title, value: template.message }))}
                                />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item required label="Patient explanation" name="patientExplanation">
                            <HugeRTEWrapper/>
                        </Form.Item>
                    </>
                )
            }
        </Spin>
    )
}

export default PatientMessage
